import axios from 'axios'
import * as React from 'react'

import { ComponentBuilder } from 'components'
// import { Homepage } from 'app/Homepage/Homepage'
import { Seo } from 'components/Seo'

export async function getStaticProps() {
  const fetchDataContent = async (payload) => {
    const baseLocalAPIPath = process.env.NEXT_PUBLIC_BASE_URL
    const NEXT_API_URL = `${baseLocalAPIPath}/api`

    return await axios({
      url: `${NEXT_API_URL}/content/v2/storage/get/content`,
      params: { ...payload },
    }).then((res) => {
      return res.data
    })
  }
  const payload = {
    pageName: 'cartool-v2-landing',
    contentType: 'DataContent',
  }
  const { data } = await fetchDataContent(payload)
  const { schema } = data || {}

  return {
    props: {
      schema,
    },
    revalidate: 100, // in seconds
  }
}

export const App = ({ schema }) => {
  return (
    <>
      <Seo templateTitle="Cartool" />

      <ComponentBuilder data={schema} />
    </>
  )
}

export default App
